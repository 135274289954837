<template>
    <AdminWrapper>
        <template v-slot:child>
            <!--Balance sheet -->
            <div class="w-90 center">
                <!--        &lt; Inventory-->
                <router-link :to="{ name: 'Reports' }" class="pv4-l pv3 mini-spacing"> &lt; Reports </router-link>

                <div class="flex justify-between items-center pb4 box-border-bottom">
                    <div>
                        <p class="f3 b">Balance Sheet</p>
                    </div>
                    <div>
                        <button>Update</button>
                    </div>
                </div>
            </div>
            <!--Balance sheet -->

            <!-- date, comparison & filter  -->
            <div class="w-90 center pt4">
                <div class="flex justify-between">
                    <div></div>
                    <div class="flex items-center flex-wrap justify-end items-center w-100 pt0-l pt3" style="gap: 1rem">
                        <div class="flex flex-wrap" style="gap: 1rem">
                            <template v-if="state.comparison === 'period1'">
                                <!-- <input v-model="state.startDate1" type="date" name="" class="input-style" disabled /> -->
                                <input v-model="state.endDate1" type="date" name="" class="input-style" disabled />
                            </template>

                            <!-- <template v-else>
                                <input v-model="state.startDate2" type="date" name="" class="input-style" />
                                <input v-model="state.endDate2" type="date" name="" class="input-style" />
                            </template> -->
                        </div>

                        <!-- <select v-model="state.comparison" name="comparison" id="">
                            <option value="period1">Comparison: 1 period</option>
                            <option value="period2">Comparison: 2 period</option>
                        </select> -->

                        <!-- <button class="filter card-box" @click.prevent="handleFilter">
                            <img src="../../assets/images/filter_list.svg" alt="filter icon" />
                            <span class="pl2 w-50 tl black">Filter</span>
                        </button> -->
                    </div>
                </div>
            </div>
            <!-- date, comparison & filter  -->

            <template v-if="$store.state.Admin.loadingState">
                <Spinner />
            </template>
            <template v-else>
                <div class="w-90 center mt4">
                    <!-- Business Details -->
                    <div class="pa3 box-border">
                        <OrgData heading="Balance Sheet" />

                        <!-- statement of profit/lose -->
                        <div class="tc pv4">
                            <div class="b" style="color: #132c8c">
                                Statement of Balance Sheet for {{ orgData?.org }} as at Date
                            </div>
                        </div>

                        <!-- accounts & date assets, liabilities and co -->
                        <div class="ph2 pt4">
                            <div class="pb3 flex justify-between b box-border-bottom">
                                <div>Accounts</div>
                                <div class="flex justify-between w-40">
                                    <!-- <div>1st Feb - 1st Dec 2021</div> -->
                                    <!-- <div class="db-l dnx">1st Feb - 1st Dec 2020</div> -->
                                </div>
                            </div>

                            <!--Current assets -->
                            <div>
                                <div class="flex items-center pv3 box-border-bottom font-w1">
                                    <img src="../../../assets/images/arrow-down.svg" alt="" />
                                    <div class="pl2 text-uppercase">Current Assets</div>
                                </div>
                                <div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom">
                                        <div class="font-w1">Cash on hand</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{ formatAmount(currentAssets?.cashOnHand, $store.state.Settings?.currency) }}
                                            </div>
                                            <!-- <div>{{ formatAmount(inventories?.end) }}</div> -->
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom">
                                        <div class="font-w1">Cash at Bank</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{ formatAmount(currentAssets?.cashAtBank, $store.state.Settings?.currency) }}
                                            </div>
                                            <!-- <div>{{ formatAmount(inventories?.end) }}</div> -->
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom">
                                        <div class="font-w1">Inventories</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{ formatAmount(currentAssets?.inventories, $store.state.Settings?.currency) }}
                                            </div>
                                            <!-- <div>{{ formatAmount(inventories?.end) }}</div> -->
                                        </div>
                                    </div>
                                    <div class="flex justify-between items-center pv3 pl3  box-border-bottom">
                                        <div class="flex items-center">
                                            <img src="../../../assets/images/arrow-down.svg" alt="" />
                                            Outstanding Receivables
                                        </div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        currentAssets?.outstandingReceivables?.totalOutstandingReceivables,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                            <!-- <div>{{ formatAmount(0) }}</div> -->
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom">
                                        <div class="font-w1">Sales Credit</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        currentAssets?.outstandingReceivables?.salesCredit,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                            <!-- <div>{{ formatAmount(inventories?.end) }}</div> -->
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom">
                                        <div class="font-w1">Loans Given Out</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        currentAssets?.outstandingReceivables?.loanGivenOut,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                            <!-- <div>{{ formatAmount(inventories?.end) }}</div> -->
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom">
                                        <div class="font-w1">Settlement/Repayment</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                ({{
                                                    formatAmount(
                                                        currentAssets?.outstandingReceivables?.settlementAndRepayment,
                                                        $store.state.Settings?.currency
                                                    )
                                                }})
                                            </div>
                                            <!-- <div>{{ formatAmount(inventories?.end) }}</div> -->
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom">
                                        <div>Bad Debt</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                ({{
                                                    formatAmount(
                                                        currentAssets?.outstandingReceivables?.badDebt,
                                                        $store.state.Settings?.currency
                                                    )
                                                }})
                                            </div>
                                            <!-- <div>{{ formatAmount(0) }}</div> -->
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl3 box-border-bottom">
                                        <div class="font-w1">Prepayment</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        currentAssets?.prepayment?.total,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                            <!-- <div>{{ formatAmount(inventories?.end) }}</div> -->
                                        </div>
                                    </div>

                                    <!-- Total current assets -->
                                    <div class="flex justify-between pv3 pl2 b box-border-bottom">
                                        <div class="font-w1x">Total current assets</div>
                                        <div class="flex justify-between w-40-l pl2 tr">
                                            <div class="db-l dnx">
                                                {{ formatAmount(currentAssets?.totalAssets, $store.state.Settings?.currency) }}
                                            </div>
                                            <!-- <div>{{ formatAmount() }}</div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--Fixed assets -->
                            <div>
                                <div class="flex items-center pv3 box-border-bottom font-w1">
                                    <img src="../../../assets/images/arrow-down.svg" alt="" />
                                    <div class="pl2 text-uppercase">Fixed Assets</div>
                                </div>
                                <div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom">
                                        <div class="font-w1">Land</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">{{ formatAmount(0, $store.state.Settings?.currency) }}</div>
                                            <!-- <div>{{ formatAmount(inventories?.end) }}</div> -->
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom">
                                        <div class="font-w1">Machinery and Equipment</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">{{ formatAmount(0, $store.state.Settings?.currency) }}</div>
                                            <!-- <div>{{ formatAmount(inventories?.end) }}</div> -->
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom">
                                        <div class="font-w1">Inventories</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{ formatAmount(Vehicles, $store.state.Settings?.currency) }}
                                            </div>
                                            <!-- <div>{{ formatAmount(inventories?.end) }}</div> -->
                                        </div>
                                    </div>

                                    <div class="flex justify-between pv3 pl2 b box-border-bottom">
                                        <div class="font-w1x">Total fixed assets</div>
                                        <div class="flex justify-between w-40-l pl2 tr">
                                            <div class="db-l dnx">{{ formatAmount(0, $store.state.Settings?.currency) }}</div>
                                            <!-- <div>{{ formatAmount() }}</div> -->
                                        </div>
                                    </div>

                                    <div class="flex justify-between pv3 pl2 b box-border-bottom">
                                        <div class="font-w1x text-uppercase">Total Assets</div>
                                        <div class="flex justify-between w-40-l pl2 tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(currentAssets?.totalAssets + 0, $store.state.Settings?.currency)
                                                }}
                                            </div>
                                            <!-- <div>{{ formatAmount() }}</div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--Liabilities -->
                            <div>
                                <div class="flex items-center pv3 font-w1 box-border-bottom">
                                    <img src="../../../assets/images/arrow-down.svg" alt="" />
                                    <div class="pl2 text-uppercase">Liabilities</div>
                                </div>
                                <!-- dropdown -->
                                <div>
                                    <div class="pv3 pl2 flex items-center font-w1 box-border-bottom">
                                        <img src="../../../assets/images/arrow-down.svg" alt="" />
                                        Outstanding Payables
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom">
                                        <div>Purchase credit</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        liabilities?.outstandingPayables?.purchaseCredit,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                            <!-- <div>{{ formatAmount(0) }}</div> -->
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom">
                                        <div>Borrowings</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        liabilities?.outstandingPayables?.borrowings,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                            <!-- <div>{{ formatAmount(0) }}</div> -->
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom">
                                        <div>Settlement/Repayment</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                ({{
                                                    formatAmount(
                                                        liabilities?.outstandingPayables?.settlementAndRepayment,
                                                        $store.state.Settings?.currency
                                                    )
                                                }})
                                            </div>
                                            <!-- <div>{{ formatAmount(0) }}</div> -->
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 b box-border-bottom">
                                        <div>Total</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        liabilities?.outstandingPayables?.total,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                            <!-- <div>{{ formatAmount(0) }}</div> -->
                                        </div>
                                    </div>
                                    <div class="flex justify-between items-center pv3 pl3  box-border-bottom">
                                        <div class="flex item-center">
                                            <img src="../../../assets/images/arrow-down.svg" alt="" />
                                            VAT Payables
                                        </div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(liabilities?.vatPayables?.total, $store.state.Settings?.currency)
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom">
                                        <div>Sales VAT</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        liabilities?.vatPayables?.salesVat,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                            <!-- <div>{{ formatAmount() }}</div> -->
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom">
                                        <div>VAT Received</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        liabilities?.vatPayables?.vatReceived,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                            <!-- <div>{{ formatAmount() }}</div> -->
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom">
                                        <div>VAT Remittance</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        liabilities?.vatPayables?.vatRemittance,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                            <!-- <div>{{ formatAmount() }}</div> -->
                                        </div>
                                    </div>
                                    <!-- Total non-current liability -->
                                    <div class="flex justify-between pv3 pl2 box-border-bottom font-w1">
                                        <div>Outstanding Customer advance</div>
                                        <div class="flex justify-between w-40-l pl2 tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        liabilities?.outstandingCustomerAdvance,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                            <!-- <div>{{ formatAmount() }}</div> -->
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl2 box-border-bottom font-w1">
                                        <div>Total liability</div>
                                        <div class="flex justify-between w-40-l pl2 tr">
                                            <div class="db-l dnx">
                                                {{ formatAmount(liabilities?.total, $store.state.Settings?.currency) }}
                                            </div>
                                            <!-- <div>{{ formatAmount() }}</div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--Equity -->
                            <div>
                                <div class="flex items-center pv3 font-w1 box-border-bottom">
                                    <img src="../../../assets/images/arrow-down.svg" alt="" />
                                    <div class="pl2 text-uppercase">Equity</div>
                                </div>
                                <!-- dropdown -->
                                <div class="flex justify-between pv3 pl4 box-border-bottom">
                                    <div>Capital</div>
                                    <div class="flex justify-between w-40-l tr">
                                        <div class="db-l dnx">
                                            {{ formatAmount(equity?.capital, $store.state.Settings?.currency) }}
                                        </div>
                                        <!-- <div>{{ formatAmount(capital?.end) }}</div> -->
                                    </div>
                                </div>
                                <div class="flex justify-between pv3 pl4 box-border-bottom">
                                    <div>Retained earnings</div>
                                    <div class="flex justify-between w-40-l tr">
                                        <div class="db-l dnx">
                                            {{ formatAmount(equity?.retainedEarninngs, $store.state.Settings?.currency) }}
                                        </div>
                                        <!-- <div>{{ formatAmount(withdrawings?.end) }}</div> -->
                                    </div>
                                </div>
                                <div class="flex justify-between pv3 pl4 box-border-bottom">
                                    <div>Withdrawal</div>
                                    <div class="flex justify-between w-40-l tr">
                                        <div class="db-l dnx">
                                            {{ formatAmount(equity?.withdrawal, $store.state.Settings?.currency) }}
                                        </div>
                                        <!-- <div>{{ formatAmount(withdrawings?.end) }}</div> -->
                                    </div>
                                </div>
                                <!-- Total Equity -->
                                <div class="flex justify-between pv3 pl2 b font-w1 box-border-bottom">
                                    <div>Total Equity</div>
                                    <div class="flex justify-between w-40-l pl2 tr">
                                        <div class="db-l dnx">
                                            {{ formatAmount(equity?.totalEquity, $store.state.Settings?.currency) }}
                                        </div>
                                        <!-- <div>{{ formatAmount() }}</div> -->
                                    </div>
                                </div>
                                <div class="flex justify-between pv3 pl2 box-border-bottom b">
                                    <div class="text-uppercase">Total Liability and Equity</div>
                                    <div class="flex justify-between w-40-l pl2 tr">
                                        <div class="db-l dnx">
                                            {{
                                                formatAmount(
                                                    liabilities?.total + equity?.totalEquity,
                                                    $store.state.Settings?.currency
                                                )
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- save as & export button -->
                            <div class="flex justify-between pv4">
                                <div></div>
                                <div class="w-auto-l w-100 flex">
                                    <button class="flex items-center">
                                        Save as &nbsp; <img src="../../../assets/images/down.svg" alt="" />
                                    </button>
                                    <button class="flex items-center ml2">
                                        Export &nbsp; <img src="../../../assets/images/down.svg" alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </AdminWrapper>
</template>

<script>
import { computed, reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
import AdminWrapper from '@/layout/AdminWrapper'
import { formatAmount, formatDateFilter } from '@/utils/lib'
import OrgData from '@/components/OrgData'
import Spinner from '@/components/Spinner'
import { useRouter } from 'vue-router'

export default {
    name: 'BalanceSheet',
    components: { AdminWrapper, OrgData, Spinner },

    setup() {
        const store = useStore()
        const orgData = computed(() => store?.state?.Auth?.userData)
        const currentAssets = computed(() => store?.state?.Admin?.getBalanceSheetReport.currentAsset)
        const liabilities = computed(() => store?.state?.Admin?.getBalanceSheetReport.liability)
        const equity = computed(() => store?.state?.Admin?.getBalanceSheetReport.equity)
        // const isLoading = computed(() => store?.state?.Reporting?.isLoading)
        const route = useRouter()

        const month = new Date().getMonth()
        const year = new Date().getFullYear()
        const day = new Date().getDate()
        const lastYear = year - 1

        const defaultStartDate_1 = new Date(year, 0, 1)
        const defaultEndDate_1 = new Date(year, month, day)

        const defaultStartDate_2 = new Date(lastYear, 0, 1)
        const defaultEndDate_2 = new Date(lastYear, month, day)

        const state = reactive({
            startDate1: defaultStartDate_1,
            endDate1: defaultEndDate_1,
            startDate2: defaultStartDate_2,
            endDate2: defaultEndDate_2,
            comparison: 'period1'
        })

        const handleFilter = () => {
            // if (state.startDate1 > state.endDate1 || state.startDate2 > state.endDate2) {
            //     return store.dispatch('Alert/setAlert', {
            //         message: 'Start date cannot be greater than end date',
            //         status: false
            //     })
            // }
            // if (!state.startDate1 || !state.endDate1 || !state.startDate2 || !state.endDate2) {
            //     return store.dispatch('Alert/setAlert', {
            //         message: 'Please make sure all dates are selected in both periods',
            //         status: false
            //     })
            // }
        }

        onMounted(() => {
            store.dispatch('Admin/getBalanceSheetReport', route.currentRoute.value.params.id)
            // console.log('liabi', liabilities.value)
        })

        return {
            state,
            orgData,
            handleFilter,
            formatAmount,
            currentAssets,
            liabilities,
            equity
            // isLoading
        }
    }
}
</script>

<style scoped></style>
